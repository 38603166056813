import {
  AuthGetLoggedUserDocument,
  AuthGetLoggedUserQuery,
} from '@features/auth/operations/query/__generated__/get-logged-user'
import { getGraphQlErrorsCode } from '@features/core/graphql/graphql-errors'
import { initializeApollo } from '@palqee/apollo-client'
import { buildUser } from './utils'
import { LoginPayload } from '@features/auth'

export const nextAuthOidc = async (credentials: {
  accessToken: string
  refreshToken: string
}) => {
  // by this point user has been authenticated via the provider
  // get info from credentials
  const accessToken = credentials.accessToken
  const refreshToken = credentials.refreshToken

  const client = initializeApollo()

  try {
    // get additional user details now authenticated
    const result = await client.query<AuthGetLoggedUserQuery>({
      query: AuthGetLoggedUserDocument,
      context: {
        headers: {
          authorization: accessToken,
        },
      },
    })

    const user = result?.data?.auth?.userCurrent?.user
    const identityProviderCode =
      result?.data?.auth?.userCurrent?.identity?.identityApplication
        ?.identityProviderCode

    return {
      // doesn't do anything in this flow but
      // for consistency pass a loginPayload success
      loginPayload: LoginPayload.LoginSuccessPayload,
      accessToken,
      refreshToken,
      isSso: true,
      ...{
        ...buildUser(user),
        identityProviderCode,
      },
    }
  } catch (error) {
    console.error(error)
    throw new Error(getGraphQlErrorsCode(error?.graphQLErrors))
  }
}
