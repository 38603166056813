import { IncomingMessage, ServerResponse } from 'http'
import { getCookie } from 'cookies-next'
import isEmpty from 'lodash/isEmpty'
import { CookieObj, CookieRequest, CookieResponse } from './types'
import { setPqCookie } from '.'

export const setAuthCookie = (
  options: {
    cookie: CookieObj
    forceSet?: boolean
  },
  req?: CookieRequest,
  res?: CookieResponse,
) => {
  const { cookie } = options

  const pqAuthCookie = getCookie('pq.auth', {
    req: req as IncomingMessage,
    res: res as ServerResponse,
  })

  let value = undefined

  if (pqAuthCookie && !isEmpty(JSON.parse(pqAuthCookie))) {
    value = JSON.stringify(
      JSON.parse(pqAuthCookie, (key, v) => {
        if (key === cookie?.key) {
          return cookie?.value
        }
        return v
      }),
    )
  } else {
    value = JSON.stringify({ [cookie?.key]: cookie?.value })
  }

  setPqCookie(
    {
      key: 'pq.auth',
      value,
    },
    req,
    res,
  )
}

export const setAuthCookies = (
  options: {
    cookies: CookieObj[]
    forceSet?: boolean
  },
  req?: CookieRequest,
  res?: CookieResponse,
) => {
  options.cookies.forEach((cookie) => setAuthCookie({ cookie }, req, res))
}

export const getAuthCookieValueByKey = (
  key: string,
  req?: CookieRequest,
  res?: CookieResponse,
) => {
  const pqAuthCookie = getCookie('pq.auth', {
    req: req as IncomingMessage,
    res: res as ServerResponse,
  })

  if (!pqAuthCookie) return null

  return JSON.parse(pqAuthCookie)[key]
}

export const getAuthCookieValue = (
  req?: CookieRequest,
  res?: CookieResponse,
) => {
  const authCookieValue = getCookie('pq.auth', {
    req: req as IncomingMessage,
    res: res as ServerResponse,
  })

  if (!authCookieValue) return null

  return JSON.parse(authCookieValue)
}
