import {
  CurrentTasksDispatch,
  CurrentTasksState,
  TEditTaskInfo,
} from '../contexts/types'
import {
  FetchResult,
  InternalRefetchQueriesInclude,
} from '@palqee/apollo-client'
import { FunctionComponent, MouseEvent, RefObject } from 'react'
import {
  IBinaryFileInfo,
  PaginationInfo,
  Scalars,
  TaskCategoryLinkInput,
  TaskCategoryUnlinkInput,
  TaskCreateInput,
  TaskTaskAssigneeAudienceLinkInput,
  TaskTaskAssigneeAudienceUnlinkInput,
  TaskTaskAssigneeUserLinkInput,
  TaskTaskAssigneeUserUnlinkInput,
  TaskUpdateInput,
} from '@generated/types.d'
import {
  TaskDetailFragment,
  TaskListDetailFragment,
} from '../operations/__generated__/fragments'
import {
  TaskLinkDsarRequestFragment,
  TaskLinkSessionFragment,
} from '../hooks/use-task-links/__generated__/fragments'

import { ILinkHandlerProps } from '../hooks/use-links-context'
import { TSurveyNotInvitedGetItem } from '../hooks/use-notinvited-get'
import { TaskCreateMutation } from '../operations/mutations/__generated__'

export enum TaskDropType {
  STATUS = 'STATUS',
  TASK = 'TASK',
  STATUS_SETTINGS = 'STATUS_SETTINGS',
}

export enum ModuleTypeCode {
  DOCUMENT = 'DOCBOX',
  SURVEY = 'SURVEY',
  DSAR = 'DSAR',
  URL = 'LINK',
}

export enum ActivityLogType {
  TASK_CREATE = 'task.create',
  TASK_TITLE = 'task.title',
  CATEGORY = 'task.category',
  STATUS = 'task.status',
  PRIORITY = 'task.priority',
  ARCHIVE = 'task.archive',
  UNARCHIVE = 'task.unarchive',
  ASSIGNEE = 'task.assignee',
  DEADLINE_AT = 'task.deadlineAt',
  LINK = 'task.link',
  DESCRIPTION = 'task.description',
  ATTACHMENT = 'task.attachment',
  COMMENT = 'task.comment',
  START_AT = 'task.startAt',
}

export type TMenuItemProps = {
  icon?: FunctionComponent<
    React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
  >
  label: string
  iconClass?: string
}

export enum StatusEditTypes {
  NAME = 'name',
  COLOR = 'color',
  BOTH = 'both',
}

export enum TaskInfoParams {
  DEADLINE_AT = 'deadlineAt',
  START_AT = 'startAt',
  STATUS_ID = 'statusId',
  PRIORITY_ID = 'priorityId',
  TITLE = 'title',
  DESCRIPTION = 'description',
  ATTACHMENT_EMBED_IDS = 'attachmentEmbedIds',
}

export enum StatusInfoParams {
  SORT_ORDER = 'sortOrder',
}

export interface IStatusFormData {
  id?: string
  name?: string
  color?: string
  sortOrder?: number
  editType?: `${StatusEditTypes}`
}

export type TUserData = {
  id: string
  firstName?: string
  lastName?: string
}

export type TAudienceData = {
  id: string
  name?: string
  audienceId?: string
}

export type TCategoryOptionProp = {
  label: string
  value: string
  color: string
  __isNew__?: boolean
}

export type TSelectOptionProps = {
  label: string
  value: string
}

export type TLinkFormData = {
  linkedUrls?: any
  linkedSurveySessions?: TSelectOptionProps[] | TaskLinkSessionFragment[]
  linkedDsarRequests?: TSelectOptionProps[] | TaskLinkDsarRequestFragment[]
  linkedDocboxDocuments?: TSelectOptionProps[]
}

export type TTaskDescription = {
  contentHtml?: string
  taggedIds?: string[]
}

export interface ITaskFormData
  extends Omit<Partial<TaskDetailFragment>, 'categories' | 'attachments'> {
  statusId?: string
  priorityId?: string
  deadlineAt?: string
  startAt?: string
  mentionedUserIds?: string[]
  fileInfoEmbedIds?: string[]
  sortOrder?: number
  links?: Partial<TLinkFormData>
  assignees?: TUserData[] | TAudienceData[]
  categories?: TCategoryOptionProp[]
  attachments?: Scalars['Upload'][]
}

export type DueDateCustomInputProps = {
  ignoreVariants?: boolean
  value?: string
  onClick?: (event: MouseEvent<HTMLElement>) => void
}

export type TUserDetailsFragment = {
  id: string
  firstName?: string
  lastName?: string
  email?: string
}

export type TSurveyProp = {
  id?: string
  name?: string
}

export type TSurveySession = {
  id?: string
  sessionId?: string
  surveyId?: string
  survey?: TSurveyProp
}

export type TTaskCommentDetail = {
  id?: string
  commentId?: string
  content?: string
  attachments?: Partial<
    TAttachmentInfo & {
      commentId?: string
      commentAttachmentId?: string
    }
  >[]
}

export type TTaskDetailsGet = {
  paginationInfo: PaginationInfo
  tasks: Partial<TaskListDetailFragment>[]
}

export type TFetchHandlerProps = {
  isFetchingMore: boolean
  fetchMoreHandler: (paginationInput) => void
  isLastPage: boolean
}

export interface WrapperProps {
  isDraggingOver?: boolean
  isDraggingFrom?: boolean
  isDropDisabled?: boolean
  isTaskCountZero?: boolean
}

export type TModuleItemProps = {
  label: string
  value: string
  type?: ModuleTypeCode
  url?: string
  surveyId?: string
  isExpired?: boolean
}

export type TAttachmentInfo = {
  id?: string
  taskId?: string
  isEmbed?: boolean
  taskAttachmentId?: string
  fileInfoId?: string
  fileInfo?: Partial<IBinaryFileInfo>
}

export interface ILinkValueListProps
  extends Pick<ILinkHandlerProps, 'handleLinkRemove'> {
  links?: TLinkFormData
  isReadOnly?: boolean
  isLoading?: boolean
}

export interface ITaskLinkProps extends ILinkValueListProps {
  link: TModuleItemProps
  uninvitedSessions: TSurveyNotInvitedGetItem[]
}

export interface TaskAttachmentActionControlsProps {
  handleFileRemove: (file: any) => void
  isEmbed: boolean
  s3FileInfo: Partial<IBinaryFileInfo>
}

export type IHandleTaskCreate = (
  inputParams: TaskCreateInput,
  props?: { refetchQueries?: InternalRefetchQueriesInclude },
) => Promise<FetchResult<TaskCreateMutation>> | Error
export type IHandleTaskUpdate = (
  inputParams: Partial<TaskUpdateInput>,
  dataConfig?: any,
  configData?: any,
) => Promise<void>

export interface IUseTaskFormHandlers {
  handleTaskCreate: IHandleTaskCreate
  handleTaskUpdate: IHandleTaskUpdate
  handleNewTaskCancel: () => void
  handleCreateClick: (statusId: string) => void
  handleAssigneeLink: (input: TaskTaskAssigneeUserLinkInput) => Promise<void>
  handleAssigneeUnlink: (
    input: TaskTaskAssigneeUserUnlinkInput,
  ) => Promise<void>
  handleAudienceLink: (
    input: TaskTaskAssigneeAudienceLinkInput,
  ) => Promise<void>
  handleAudienceUnlink: (
    input: TaskTaskAssigneeAudienceUnlinkInput,
  ) => Promise<void>
  handleTaskCategoryLink: (input: TaskCategoryLinkInput) => Promise<void>
  handleTaskCategoryUnlink: (input: TaskCategoryUnlinkInput) => Promise<void>
  handleTaskDelete: (taskId: string, statusId: string) => Promise<void>
  handleTaskMoveToArchive: (taskId: string, statusId: string) => Promise<void>
  handleTaskCopy: (taskId: string, statusId: string) => Promise<void>
  handleTaskLinkUpdate: (
    value: string,
    type: `${ModuleTypeCode}`,
    isUnlink?: boolean,
  ) => void
  handleTaskUnarchive: (taskId: string) => Promise<void>
  onSubmit: (
    props: ITaskFormOnSubmitProps,
  ) => (data: ITaskFormData) => Promise<void>
}

export interface IUseTaskFormReturn {
  handlers: IUseTaskFormHandlers
  taskCreateLoading: boolean
  taskUpdateLoading: boolean
  taskUpdateId: string
  newTaskStatusId: string
  linkAssigneeLoading: boolean
  unlinkAssigneeLoading: boolean
  linkCategoryLoading: boolean
  unlinkCategoryLoading: boolean
  taskDeleteLoading: boolean
  taskArchiveLoading: boolean
  taskCopyLoading: boolean
  linkAudienceLoading: boolean
  unlinkAudienceLoading: boolean
  isLinkLoading: boolean
  isUnlinkLoading: boolean
}

export interface ITaskFormProps {
  isUpdating?: boolean
  taskItemDetails?: Partial<TaskDetailFragment>
  taskDetailsLoading?: boolean
  taskStore?: CurrentTasksState
  taskContextDispatch?: CurrentTasksDispatch
  isOpen: boolean
  onCloseHandler?: () => void
  refetchQueries?: InternalRefetchQueriesInclude
}

export interface ITaskFormCommonProps {
  isUpdating: boolean
  taskFormData: ITaskFormData
}

export interface ITaskFormContextProps
  extends ITaskFormCommonProps,
    Pick<IUseTaskFormReturn, 'handlers' | 'isLinkLoading' | 'isUnlinkLoading'> {
  onSubmit: (props: ITaskFormData) => Promise<void>
  isReadOnly: boolean
  scrollRef: RefObject<HTMLDivElement>
}

export interface ITaskFormDateInputProps {
  label: string
  name: string
  doneStatusId: string
  editTaskInfo: TEditTaskInfo
}

export interface ITaskFormOnSubmitProps extends ITaskFormCommonProps {
  renderSuccessToast: (props: { createdTask: TaskDetailFragment }) => void
  refetchQueries?: InternalRefetchQueriesInclude
  handleClose: (isDeleteStorage?: boolean) => void
}

export interface IStatusListProps {
  statusList: any
  statusUpdateId: string
  taskUpdateId: string
  statusReorderLoading: boolean
  collapseStatusIds: string[]
  taskReorderLoading: boolean
  handlers: any
}
