import { GetServerSidePropsContext } from 'next'
import { deleteCookie } from 'cookies-next'

export const clientSideClearLoginCookies = () => {
  document.cookie =
    'next-auth.session-token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export const clearLoginCookies = (ctx?: GetServerSidePropsContext) => {
  if (typeof window === 'undefined') {
    if (ctx?.req) {
      deleteCookie('next-auth.session-token', { req: ctx?.req, res: ctx?.res })
    }
  } else {
    deleteCookie('next-auth.session-token')
  }
}
