import { User } from '@generated/types.d'
import { IAccessorFields, IUserModelProps } from './types'

const buildRole = <T>(user: T) => {
  const userRole = user as unknown as User

  if (Array.isArray(userRole?.positions) && userRole.positions.length > 0) {
    return userRole.positions?.[0]?.position
  }

  return undefined
}

const buildDepartment = <T>(user: T) => {
  const userDepartment = user as unknown as User

  if (
    Array.isArray(userDepartment?.positions) &&
    userDepartment.positions.length > 0
  ) {
    return userDepartment.positions?.[0]?.department
  }

  return undefined
}

const parseUserByAccessor = <T, K>(
  user: T,
  accessorFields: IAccessorFields[],
): K => {
  const parsedUserData = Object.entries(user)
    .filter((mappedData) => {
      const [userKey] = mappedData
      if (accessorFields.length > 0) {
        return accessorFields.some((accessorField) => accessorField === userKey)
      }

      return true
    })
    .reduce((prev, mappedData) => {
      const [key, value] = mappedData
      return {
        ...prev,
        [key]: value,
      }
    }, {} as K)

  return parsedUserData
}

export const buildUserModel =
  <T, K>(props?: IUserModelProps) =>
  (user: T): K => {
    const { accessorFields = [] } = props ?? {}

    const userData = {
      ...user,
      role: buildRole(user),
      department: buildDepartment(user),
    }

    return parseUserByAccessor(userData, accessorFields)
  }

export const buildUsersModel = <T, K>(
  users: T[],
  props?: IUserModelProps,
): K[] => {
  if (!users || users.length === 0) return []

  return users.map(buildUserModel<T, K>(props))
}
