import { AuthLoginDocument, AuthRoleCodeEnum } from '@generated/types.d'
import { LoginPayload } from '../../types'
import { initializeApollo } from '@palqee/apollo-client'
import { getGraphQlErrorsCode } from '@features/core/graphql/graphql-errors'
import { checkRoleByCompany } from './utils'

export const nextAuthLogin = async (credentials: {
  username: string
  password: string
  rememberMe: boolean
}) => {
  const { username, password, rememberMe } = credentials

  try {
    const client = initializeApollo()
    const result = await client.mutate({
      variables: {
        username,
        password,
      },
      mutation: AuthLoginDocument,
    })

    const {
      login: {
        __typename,
        accessToken,
        refreshToken,
        expiresAfterSeconds,
        user,
        mfaType,
        mfaChallengeKey,
      },
    } = result?.data?.auth

    // for success payload on login on retrieve relevant info
    let extra = {}
    if (__typename === LoginPayload.LoginSuccessPayload) {
      const {
        email,
        id,
        isMfaEnabled,
        language,
        roles,
        companies,
        defaultCompanyId,
        passwordLastChangeAt,
      } = user

      // use default copany id or the first company in the array
      const newDefaultCompanyId = defaultCompanyId ?? companies?.[0]?.id

      extra = {
        id,
        email,
        isMfaEnabled,
        language,
        passwordLastChangeAt,
        isAdmin: checkRoleByCompany(
          roles,
          newDefaultCompanyId,
          AuthRoleCodeEnum.Administrator,
        ),
        defaultCompanyId: newDefaultCompanyId,
      }
    }

    return {
      // return type is expecting an id
      id: null,
      mfaType,
      isSso: false,
      accessToken,
      refreshToken,
      rememberMe,
      mfaChallengeKey,
      expiresAfterSeconds,
      loginPayload: __typename,
      email: username,
      ...extra,
    }
  } catch (error) {
    console.error(error)
    throw new Error(getGraphQlErrorsCode(error?.graphQLErrors))
  }
}
