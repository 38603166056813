import { getGraphQlErrorsCode } from '@features/core/graphql/graphql-errors'
import {
  AuthLoginConfirmDocument,
  UserUpsertDocument,
} from '@generated/types.d'
import { initializeApollo } from '@palqee/apollo-client'
import { captureException } from '@sentry/nextjs'
import { buildUser } from './utils'
import { NextApiRequest, NextApiResponse } from 'next'
import { ServerRequest } from '@features/core/next-auth/types/next-auth'
import { IncomingMessage, ServerResponse } from 'http'
import { getAuthCookieValueByKey } from '../cookie/auth'

/**
 * Sets default companyId when null
 */
const handleDefaultCompany = (props: {
  userId: string
  companyId: string
  accessToken: string
}) => {
  const { userId, companyId, accessToken } = props
  const client = initializeApollo()
  try {
    client.mutate({
      variables: {
        userId,
        defaultCompanyId: companyId,
      },
      mutation: UserUpsertDocument,
      context: {
        headers: {
          authorization: accessToken,
        },
      },
    })
  } catch (error) {
    captureException(error)
    console.error(error)
  }
}

export const nextAuthMfaConfirm =
  (
    req: NextApiRequest | ServerRequest,
    res: NextApiResponse | ServerResponse<IncomingMessage>,
  ) =>
  async ({ confirmationCode, mfaType, mfaChallengeKey, username }) => {
    // check if remember cookie set
    const rememberMe = getAuthCookieValueByKey('rememberMe', req, res)

    const client = initializeApollo()
    try {
      const result = await client.mutate({
        variables: {
          input: {
            username,
            mfaType,
            mfaChallengeKey,
            confirmationCode,
          },
        },
        mutation: AuthLoginConfirmDocument,
      })

      const {
        __typename,
        accessToken,
        refreshToken,
        expiresAfterSeconds,
        user,
      } = result?.data?.auth?.loginConfirm

      const currentCompanyId: string =
        user?.defaultCompanyId ?? user?.companies?.[0]?.id

      // we need to update this to the first
      // company in the array if not already set
      // note: this is updated when user
      // switches companies
      if (user?.defaultCompanyId === null && !!currentCompanyId) {
        handleDefaultCompany({
          userId: user?.id,
          companyId: currentCompanyId,
          accessToken,
        })
      }

      return {
        loginPayload: __typename,
        accessToken,
        refreshToken,
        expiresAfterSeconds,
        rememberMe,
        // if using MFA then not sso
        isSso: false,
        ...buildUser(user),
      }
    } catch (error) {
      console.error(error)
      throw new Error(getGraphQlErrorsCode(error?.graphQLErrors))
    }
  }
