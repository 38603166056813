import {
  TGenerateAssessmentRoute,
  TGenerateDocumentRoute,
  TGenerateDsarRoute,
  IGenerateAudienceRoute,
  IGenerateAnalyticsRoute,
} from '../types'

export const generateAssessmentSummaryMainRoute = ({
  companyId,
  surveyId,
  sessionId,
  isAdmin,
  isExpired,
}: TGenerateAssessmentRoute) => {
  if (isAdmin) {
    return `/${companyId}/surveys/manager/view-answers/${surveyId}/${sessionId}/`
  }

  if (isExpired) {
    return `/${companyId}/respondents/${surveyId}/${sessionId}/view-answers/`
  }

  return `/${companyId}/respondents/${surveyId}/${sessionId}/`
}

export const generateDocumentMainRoute = ({
  companyId,
  isAdmin,
}: TGenerateDocumentRoute) => {
  if (isAdmin) {
    return `/${companyId}/documents/library/`
  }

  return `/${companyId}/respondents/documents/`
}

export const generateDsarRequestRoute = ({
  companyId,
  requestId,
  isAdmin,
}: TGenerateDsarRoute) => {
  if (isAdmin) {
    return `/${companyId}/dsar/requests/${requestId}/`
  }

  return ''
}

export const generateAudiencesRoute = ({
  companyId,
}: IGenerateAudienceRoute) => {
  return `/${companyId}/audiences/`
}

export const generateAudienceRoute = ({
  companyId,
  audienceId,
}: IGenerateAudienceRoute) => {
  return `/${companyId}/audiences/${audienceId ?? 'new'}`
}

export const generateAnalyticsRoute = ({
  companyId,
  dashboardId,
}: IGenerateAnalyticsRoute) => {
  return `/${companyId}/analytics/${dashboardId}`
}

export const generateTaskRoute = ({
  companyId,
  taskId,
}: {
  companyId: string
  taskId: string
}) => {
  return `/${companyId}/tasks/?taskId=${taskId}`
}

export const generateMainStaticRoutes = ({
  companyId,
  page,
  subPage,
}: {
  companyId: string
  page: string
  subPage?: string
}) => {
  if (!!subPage) return `/${companyId}/${page}/${subPage}/`

  return `/${companyId}/${page}/`
}

export const generateDsarFormsRoute = ({ companyId }) => {
  return `${generateMainStaticRoutes({
    companyId,
    page: 'dsar',
    subPage: 'forms',
  })}`
}

export const generateDsarFormRoute = ({ companyId, formId }) => {
  return `${generateMainStaticRoutes({
    companyId,
    page: 'dsar',
    subPage: 'forms',
  })}${formId}/`
}

export const generateAssessmentManagerRoute = ({ companyId }) => {
  return `${generateMainStaticRoutes({
    companyId,
    page: 'surveys',
    subPage: 'manager',
  })}`
}

export const generateConsentFormsRoute = ({ companyId }) => {
  return `${generateMainStaticRoutes({
    companyId,
    page: 'consent',
    subPage: 'forms',
  })}`
}

export const generateConsentFormRoute = ({ companyId, formId }) => {
  return `${generateConsentFormsRoute({
    companyId,
  })}${formId}/`
}

export const generateAccountPersonalInfoRoute = ({
  companyId,
}: {
  companyId: string
}) => {
  return `${generateMainStaticRoutes({
    companyId,
    page: 'account',
    subPage: 'personal-information',
  })}`
}

export const generateDashboardRoute = ({
  companyId,
  isAdmin,
}: {
  companyId: string
  isAdmin: boolean
}) => {
  const urlObj = isAdmin
    ? {
        companyId,
        page: 'surveys',
        subPage: 'manager',
      }
    : { companyId, page: 'dashboard' }
  return generateMainStaticRoutes(urlObj)
}

export const generateConsentPageRoute = ({
  companyId,
  formId,
  locale,
}: {
  companyId: string
  formId: string
  locale: string
}) => {
  return `${process.env.NEXT_PUBLIC_CONSENT_DOMAIN_URL}/${locale}/${companyId}/${formId}/`
}

export const generateDsarPageRoute = ({
  companyId,
  formId,
}: {
  companyId: string
  formId: string
}) => {
  return `${process.env.NEXT_PUBLIC_DSAR_DOMAIN_URL}/${companyId}/${formId}/`
}
