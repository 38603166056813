import { getCookie, setCookie } from 'cookies-next'
import { IncomingMessage, ServerResponse } from 'http'
import { CookieObj, CookieRequest, CookieResponse } from './types'

export const setPqCookie = (
  cookie: CookieObj,
  req?: CookieRequest,
  res?: CookieResponse,
) => {
  setCookie(cookie.key, cookie.value, {
    req: req as IncomingMessage,
    res: res as ServerResponse,
    path: '/',
    httpOnly: true,
    sameSite: 'lax',
    secure: process.env.NODE_ENV === 'production',
  })
}

export const getCookieValue = (
  cookieKey: string,
  req?: CookieRequest,
  res?: CookieResponse,
) => {
  const cookieValue = getCookie(cookieKey, {
    req: req as IncomingMessage,
    res: res as ServerResponse,
  })

  if (!cookieValue) return null

  return JSON.parse(JSON.stringify(cookieValue))
}
