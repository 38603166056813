import { FC, useEffect } from 'react'
import { signIn, useSession } from 'next-auth/react'

interface AuthProps {
  role?: string
}

const Auth: FC<React.PropsWithChildren<AuthProps>> = (props) => {
  const { children, role } = props
  const { data: session, status } = useSession({
    required: false,
  })

  const authenticated = !!session?.user

  useEffect(() => {
    if (status === 'loading') return // Do nothing while loading
    if (!authenticated) signIn() // If not authenticated, force log in
  }, [authenticated, status])

  if (authenticated) {
    if (role === '') {
      // do somehting with role
    }
    return <>{children}</>
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <div>Loading...</div>
}

export { Auth }
